import React, { useEffect,useRef   } from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import useFilter from '../utils/useFilter'
import { graphql, useStaticQuery } from "gatsby"
import { useMemo, useCallback } from "react"
import { useInView } from 'react-intersection-observer'
const ProjectFeed = ({ portfolio }) => {

  const { filters } = useStaticQuery(graphql`
    query Filters{
      filters:allDatoCmsCategory {
        nodes {
          name
        }
      }
    }
  `)


  const filterList = useMemo(() => {
    return ['all', ...filters.nodes.map(current => current['name'])]
  }, [filters])


  const { filtered, filterBy, currentFilter } = useFilter(portfolio, 'category.name')


  const changeBackgroundcolor = useCallback((color) => {
    if (window.innerWidth > 768) {
      document.body.style.backgroundColor = color;
    }
  },[])

  const resetBackgroundColor = useCallback(() => {
    document.body.style.backgroundColor = 'inherit'
  },[])




  const filterProjects = useCallback((filter) => {
    document.querySelector('.filter--bar').classList.remove('active');
    filterBy(filter)
  }, [filterBy])

  const filterButtons = useMemo(() => {
    return filterList.map((filter, index) => {
      return <button className={'filter-button hover ' + (currentFilter === filter.toLowerCase() ? 'active' : '')} key={index} onClick={() => filterProjects(filter)}>{filter}</button>
    })
  }, [currentFilter, filterProjects, filterList])


  const showFilters = () => {
    document.querySelector('.filter--bar').classList.add('active');
  }

  return (
    <div className="project--feed max-1600 ma p40">
      <div className="m0 mb40 medium">
        <span className="filter--button hover" role="presentation" onClick={(event) => showFilters()} onFocus={(event) => showFilters()} onMouseOver={(event) => showFilters()} onKeyDown={(event) => showFilters()}>Filter</span>
        — <span className="active--filter">{currentFilter}</span> <div className="filter--bar">{filterButtons}</div>
      </div>
      <div className="grid grid-5 max-1600 ma">
        <Projects filtered={filtered} changeBackgroundcolor={changeBackgroundcolor} resetBackgroundColor={resetBackgroundColor} />
      </div>
    </div>
  )
}
export default ProjectFeed






function Projects({ filtered, changeBackgroundcolor, resetBackgroundColor }) {

  return filtered.map((project,) => {
    return (
      <Thumbnail key={project.slug} project={project} resetBackgroundColor={resetBackgroundColor} changeBackgroundcolor={changeBackgroundcolor} />
    )
  })

}

function Thumbnail({ project, changeBackgroundcolor, resetBackgroundColor }) {

  const [inViewRef, inView] = useInView();
  const ref = useRef()


  useEffect(() => {
    if (inView) {
      ref?.current?.classList?.add('sal-animate')
    }
  }, [inView])

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

 

  const color = project.color?.hex

  return (
    <div ref={setRefs} className={"flex align-bottom fade--in "} data-sal key={project.slug}>
      <Link onMouseLeave={resetBackgroundColor} onMouseEnter={() => changeBackgroundcolor(color)} to={'/portfolio/' + project.slug} className="link flex hover--zoom mta">
        <div className="project flex pointer-none">
          <div className="internal mta w-100">
            <div className="overflow-hidden">
              <GatsbyImage className="hover-item" width='1000' image={project.heroImage.gatsbyImageData} alt={project.title} />
            </div>
            <p className="m0 mb10 mt10 spacing uppercase m-xs" style={{ height: '40px' }}>{project.title}{project.date ? ', ' + project.date : ''}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}