import { useState } from 'react'
import { filter } from 'lodash'
import { useCallback } from 'react'
import { useEffect } from 'react'

export default function useFilter(toFilter, filterProp,) {

    const [filtered, setFiltered] = useState(toFilter)
    const [currentFilter, setCurrentFilter] = useState('')

    function formatFilter(name) {
        return name.toLowerCase()
    }

    const setFilter= useCallback((name) => {
        const url = new URL(window.location.href)
        const params =  new URLSearchParams(url.search)
        params.set('filter',formatFilter(name))
      
        setCurrentFilter(name)
    },[])

    const filterBy = useCallback((name) => {
        const [upperCatergory, prop] = filterProp.split('.')

        setFilter(formatFilter(name))

        if (name === '' || name === '*' || name === 'all') {
            setFiltered(toFilter)
            return
        }

        setFiltered(filter(toFilter, (item) => {

            return item[upperCatergory].some((item) => formatFilter(item[prop]) === formatFilter(name))
        }))
    }, [filterProp, toFilter, setFilter])


    useEffect(() =>{
        const url = new URL(window.location.href)
        const params =  new URLSearchParams(url.search)
        filterBy(params.get('filter') || 'all' )
    },[filterBy])

    return { filterBy, filtered, currentFilter }
}