import * as React from "react"
import Layout from "../components/layout"
import Portfolio from "../components/portfolio"
import Spacer from "../components/spacer"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

function FolioPage ({data}) {

		var portfolio = data.allDatoCmsPortfolio.nodes;
	  return (
		  <Layout>
		   	<SEO title="Portfolio" />
		   	<Spacer className="m-small" />
		   	<Portfolio portfolio={portfolio} />
		  </Layout>
		)
}

export default FolioPage

export const query = graphql`
query PortfolioQuery {
  allDatoCmsPortfolio(sort: {fields: position}) {
    nodes {
    	slug
    	date 
      title
      position
	  	category {
		  	name
	  	}
	  	color:projectColour {
        hex
      }
      heroImage {
    		gatsbyImageData(layout: CONSTRAINED, width: 700)
    	}
    }
  }
}
`